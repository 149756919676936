import { CreateType } from '@bgl/smartdoc-image-annotator'
import autobind from 'autobind-decorator'
import { List } from 'immutable'

import { AnnotationType } from '../utils/types'
import { allCustomerTrainProjectTypes, ProjectType } from './ProjectType'
import { Tag } from './Tag'
import { TrainingModelSize } from './TJob'

export type ProjectId = string
export interface ProjectDTO {
  id?: string
  name: string
  plan: ProjectPlan
  freeCreditsLeft: number
  projectType: ProjectType
  description?: string
  createdBy?: string
  createdTime?: string
  isItemised?: boolean
  alias?: string
  isSyncing?: boolean
  apiKeys?: string[]
  businessId?: string
  webhookUrl?: string
  webhookKey?: string
  useFileExpiresInMinutes?: number
  metadata?: ProjectMetadata
}

export interface ICheckUniqueAlias {
  ok: boolean
  message: string
}

export enum ProjectPlan {
  Free = 'Free_Capped',
  Unlimited = 'Unlimited',
  Paid = 'Pro_Paid'
}

export type ProjectMetadata = {
  redactionStyle?: string
  redactionFields?: string[]
  temporaryAiProjectType?: string // TODO: Remove later once AI Assistant project types are added
  temporaryAiProjectId?: string // TODO: Remove later once AI Assistant project id are added
}

export const redactionFieldsSet = [
  'ABN',
  'ACN',
  'BankAccountNumber',
  'Date',
  'EmailAddress',
  'FinancialAmounts',
  'OrganisationName',
  'PersonName',
  'PhoneNumber',
  'ReferenceNumber',
  'StreetAddress',
  'TFN',
  'URL'
]

export const redactionStyles = [
  {
    value: 'BlackBlock',
    label: 'Blackout',
    description: 'Redacted items will be removed and covered by a black box.'
  },
  {
    value: 'X',
    label: 'Mask with "X"',
    description: 'Each character of redacted items will be replaced with an "X"'
  },
  {
    value: 'Pseudo',
    label: 'Pseudonymisation',
    description: 'Redacted items will be replaced by artificial identifiers.'
  }
]

@autobind
export default class Project {
  constructor(
    public id: string = '',
    public name: string = '',
    public plan: ProjectPlan = ProjectPlan.Paid,
    public freeCreditsLeft: number | null = null,
    public description: string | undefined = undefined,
    public projectType: ProjectType = ProjectType.FreeForm,
    public createdBy: string | undefined = undefined,
    public createdTime: string | undefined = undefined,
    public isItemised: boolean | undefined = undefined,
    public alias: string | undefined = undefined,
    public isSyncing: boolean | undefined = undefined,
    public businessId: string | undefined = undefined,
    public webhookUrl: string | undefined = undefined,
    public webhookKey: string | undefined = undefined,
    public useFileExpiresInMinutes: number | undefined = undefined,
    public metadata: ProjectMetadata | undefined = undefined,
    public tags: Tag[] = []
  ) {}

  static fromJson(projectDTO: ProjectDTO): Project {
    const {
      id,
      name,
      plan,
      freeCreditsLeft,
      description,
      projectType,
      createdBy,
      createdTime,
      isItemised,
      alias,
      isSyncing,
      businessId,
      webhookUrl,
      webhookKey,
      useFileExpiresInMinutes,
      metadata
    } = projectDTO
    return new Project(
      id,
      name,
      plan,
      freeCreditsLeft,
      description,
      projectType,
      createdBy,
      createdTime,
      isItemised,
      alias,
      isSyncing,
      businessId,
      webhookUrl,
      webhookKey,
      useFileExpiresInMinutes,
      metadata
    )
  }

  copy({
    id = this.id,
    name = this.name,
    plan = this.plan,
    freeCreditsLeft = this.freeCreditsLeft,
    description = this.description,
    projectType = this.projectType,
    createdBy = this.createdBy,
    createdTime = this.createdTime,
    isItemised = this.isItemised,
    alias = this.alias,
    isSyncing = this.isSyncing,
    businessId = this.businessId,
    webhookUrl = this.webhookUrl,
    webhookKey = this.webhookKey,
    useFileExpiresInMinutes = this.useFileExpiresInMinutes,
    metadata = this.metadata,
    tags = this.tags
  }: Partial<Project>): Project {
    return new Project(
      id,
      name,
      plan,
      freeCreditsLeft,
      description,
      projectType,
      createdBy,
      createdTime,
      isItemised,
      alias,
      isSyncing,
      businessId,
      webhookUrl,
      webhookKey,
      useFileExpiresInMinutes,
      metadata,
      tags
    )
  }

  setId(id: string): Project {
    return this.copy({ id })
  }

  setName(name: string): Project {
    return this.copy({ name })
  }

  setPlan(plan: ProjectPlan.Paid | ProjectPlan.Free): Project {
    return this.copy({ plan })
  }

  setDescription(description: string): Project {
    return this.copy({ description })
  }

  setProjectType(projectType: ProjectType): Project {
    return this.copy({ projectType })
  }

  setIsItemised(isItemised: boolean): Project {
    return this.copy({ isItemised })
  }

  setAlias(alias: string) {
    return this.copy({ alias })
  }

  setWebhookUrl(webhookUrl: string) {
    return this.copy({ webhookUrl })
  }

  setWebhookKey(webhookKey: string) {
    return this.copy({ webhookKey })
  }

  setAssistantId(assistant: string) {
    return this.copy({
      metadata: { ...this.metadata, temporaryAiProjectId: assistant }
    })
  }

  setUseFileExpiresInMinutes(useFileExpiresInMinutes: number | undefined) {
    return this.copy({ useFileExpiresInMinutes })
  }

  setMetadata(metadata: ProjectMetadata): Project {
    return this.copy({ metadata })
  }

  setBusinessId(businessId: string) {
    return this.copy({ businessId })
  }

  setIsSyncing(isSyncing: boolean): Project {
    return this.copy({ isSyncing })
  }

  private isSystemFormProject(): boolean {
    return new Set([
      'mvtrql6OxKaGTO',
      'c2blk4Bskg348W',
      '1xv0qHpSF6JqVi',
      '9ekmuXccUBjCr6',
      'USLn3425y7EaI'
    ]).has(this.id ? this.id : '')
  }

  isTrainingSupported(): boolean {
    const freeFormOrClassificationOrObjectDetection = [
      ProjectType.FreeForm,
      ProjectType.MultiLabelClassification,
      ProjectType.MultiClassClassification,
      ProjectType.ObjectDetection,
      ProjectType.ObjectCounting
    ].includes(this.projectType)
    return (
      this.isSystemFormProject() || freeFormOrClassificationOrObjectDetection
    )
  }

  isWordTagging(): boolean {
    return !this.isObjectDetection() && !this.isPageTagging()
  }

  isPageTagging(): boolean {
    return (
      this.projectType === ProjectType.BusinessModelType ||
      this.projectType === ProjectType.SplitDocument ||
      this.projectType === ProjectType.MultiLabelClassification ||
      this.projectType === ProjectType.MultiClassClassification
    )
  }

  isObjectDetection(): boolean {
    return (
      this.projectType === ProjectType.ObjectDetection ||
      this.projectType === ProjectType.SignatureDetection ||
      this.projectType === ProjectType.ObjectCounting
    )
  }

  isObjectCounting(): boolean {
    return this.projectType === ProjectType.ObjectCounting
  }

  isPrivacyRedaction(): boolean {
    return this.projectType === ProjectType.PrivacyRedaction
  }

  isAiAssistant(): boolean {
    return this.metadata?.temporaryAiProjectType === 'ai-assistant' // TODO: remove later once ai-assistant is added to the project type
  }

  isCustomerTrainedType(): boolean {
    return allCustomerTrainProjectTypes.includes(this.projectType)
  }
  getCreateType(): CreateType {
    return this.isObjectCounting() ? CreateType.Dot : CreateType.Rect
  }

  canSetup(): boolean {
    return this.name !== ''
  }

  isValid(): boolean {
    return Boolean(this.name)
  }

  isNameValid(): boolean {
    return this.name ? this.name.length >= 3 && this.name.length <= 250 : false
  }

  isAliasValid(): boolean {
    return this.alias ? /^[a-z0-9]{3,20}$/.test(this.alias) : true
  }

  isWebhookUrlValid(): boolean {
    return this.webhookUrl
      ? /^https:\/\/\w/.test(this.webhookUrl)
      : !this.webhookKey
  }

  isUseFileExpiresInMinutesValid(): boolean {
    return this.useFileExpiresInMinutes
      ? this.useFileExpiresInMinutes > 0 &&
          this.useFileExpiresInMinutes <= 1439998560
      : true
  }

  setInvalid(): Project {
    return this.copy({
      name: this.name || ''
    })
  }

  getAnnotationType(): AnnotationType {
    switch (this.projectType) {
      case ProjectType.MultiLabelClassification ||
        ProjectType.MultiClassClassification:
        return 'pageAnnotation'
      case ProjectType.ObjectDetection:
        return 'objectAnnotation'
      default:
        return 'wordAnnotation'
    }
  }

  toAddProjectDTO(): Partial<ProjectDTO> {
    return {
      id: this.id || undefined,
      name: this.name ?? '',
      description: this.description || undefined,
      isItemised: this.isItemised,
      alias: this.alias || undefined,
      projectType: this.projectType,
      webhookUrl: this.webhookUrl || undefined,
      webhookKey: this.webhookKey || undefined,
      useFileExpiresInMinutes: this.useFileExpiresInMinutes || undefined,
      metadata: this.metadata || undefined
    }
  }

  toUpdateProjectDTO() {
    return {
      name: this.name,
      description: this.description || null,
      alias: this.alias || null,
      isItemised: this.isItemised,
      webhookUrl: this.webhookUrl || null,
      webhookKey: this.webhookKey || null,
      plan: this.plan === ProjectPlan.Unlimited ? undefined : this.plan,
      useFileExpiresInMinutes: this.useFileExpiresInMinutes || null,
      metadata: this.metadata || null
    }
  }

  getTrainingModelSize(): TrainingModelSize[] {
    if (this.projectType === ProjectType.ObjectCounting) {
      return [TrainingModelSize.Lightning]
    } else if (
      this.projectType === ProjectType.ObjectDetection ||
      this.projectType === ProjectType.SignatureDetection
    ) {
      return [
        TrainingModelSize.Lightning,
        TrainingModelSize.Normal,
        TrainingModelSize.Large,
        TrainingModelSize.XLarge,
        TrainingModelSize.XXLarge
      ]
    } else {
      return Object.values(TrainingModelSize)
    }
  }

  isClassificationProject(): boolean {
    return (
      this.projectType === ProjectType.MultiLabelClassification ||
      this.projectType === ProjectType.MultiClassClassification
    )
  }

  detailReportLabelIdMatchModelTags(): boolean {
    return (
      this.projectType === ProjectType.ObjectDetection ||
      this.projectType === ProjectType.MultiLabelClassification ||
      this.projectType === ProjectType.MultiClassClassification
    )
  }

  getNameErrorMessage(name: string): string {
    if (!name) return ''
    if (name.length <= 3 || name.length > 250) {
      return 'Project name should be between 3 to 250 letters long'
    }
    return ''
  }
}

export class Projects {
  constructor(public readonly projects: List<Project>) {}

  static fromJson(projectsDTO: ProjectDTO[]): Projects {
    const projects = projectsDTO.map((projectDTO) =>
      Project.fromJson(projectDTO)
    )
    return new Projects(List(projects))
  }
}
